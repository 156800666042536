import styled from "@emotion/styled"
import { colors, fonts } from "../../components/theme"

export const PostHero = styled.section`
  background-color: ${colors.gray};
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const PostContentLeft = styled.div`
  @media (min-width: 1024px) {
    max-width: 55%;
    margin-right: auto;
  }
`

export const PostContent = styled.section`
  padding: 5%;
  position: relative;
  min-height: 700px;

  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column-reverse;

    ${PostContentLeft} {
      margin-top: 4rem;
    }
  }
`

export const PostContentLeftBody = styled.div`
  line-height: 2;
  font-size: 1.125rem;
  color: ${colors.army};

  :last-of-type {
    margin-bottom: 4rem;
  }

  @media (min-width: 1024px) {
    :last-of-type {
      margin-bottom: 0;
    }
  }
`

export const PostContentDate = styled.p`
  ${fonts.lato.black};
  color: ${colors.olive};
  text-transform: uppercase;
  font-size: 0.875rem;
  letter-spacing: 3px;
`

export const PostContentRight = styled.div`
  @media (min-width: 1024px) {
    position: absolute;
    right: 4rem;
    top: -6rem;
    min-width: 33vw;
    min-height: 33vw;

    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 2rem;
      right: 2rem;
      background-color: ${colors.tan};
    }
  }
`

export const PostTitle = styled.h1`
  ${fonts.play};
  font-size: 3rem;
  color: ${colors.darkolive};
  max-width: 665px;
  margin: 3.5rem 0 0;
  padding-left: 5vw;
`

export const PostNextTitle = styled.p`
  margin-top: 8rem;
  ${fonts.play};
  font-size: 2rem;
  color: ${colors.darkolive};
`
